import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/Layout"
import HeadMeta from "../components/HeadMeta"
import PageHeader from "../components/PageHeader"
import Breadcrumb from "../components/Breadcrumb"
import BrowseList from "../components/BrowseList"

const Electorates = ({ data }) => {
  const elecEdges = data.allElectorateCsv.edges
  const addedSlugs = [];
  const electorateList = elecEdges.map(edge => {
    return {
      fields: {
        name: edge.node.fields.description,
        slug: edge.node.fields.slug,
      },
    }
  }).reduce((agg, item) => {
    if (addedSlugs.indexOf(item.fields.slug) < 0) {
      agg.push(item);
      addedSlugs.push(item.fields.slug);
    }
    return agg;
  }, []);
  return (
    <Layout>
      <HeadMeta pageTitle={`NSW Electorates`} />
      <PageHeader>NSW Electorates</PageHeader>
      <Breadcrumb
        route={[
          { name: "Parliamentary Papers", href: "/" },
          { name: "NSW Electorates", href: "/electorates/" },
        ]}
      />
      <BrowseList
        items={electorateList}
        sortFunc={(a, b) => {
          const getSearchName = (name) => {
            return name.replace(/\(.+\)$/, '')
              .replace(/(^\s*|\s*$)/g, '')
              .replace(/[^a-z\s]/gi, '')
              .split(' ')
              .pop()
              .toLowerCase();
          }
          const nameA = getSearchName(a.fields.name);
          const nameB = getSearchName(b.fields.name);
          if (nameA.toLowerCase() < nameB.toLowerCase()) {
            return -1
          }
          if (nameA.toLowerCase() > nameB.toLowerCase()) {
            return 1
          }
          return 0
        }}
      />
    </Layout>
  )
}

export default Electorates;

export const query = graphql`
  query ElectorateIndexQuery {
    allElectorateCsv(filter: {fields: {mla: {nin: [null, ""]}}}) {
      edges {
        node {
          fields {
            slug
            description
          }
        }
      }
    }
  }
`
